<template>
  <div id="signalReturn">碎片信号回流分析</div>
</template>

<script>
export default {
  data() {
      return {

      }
  }
}
</script>

<style lang="scss">
#signalReturn {
}
</style>
